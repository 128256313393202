import { Container, Grid, Paper } from "@mui/material";
import {
  Apartment,
  Category,
  Chat,
  Map,
  StarRounded,
  Upload,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import StyledButton from "../styled/StyledButton";
import axios from "axios";
import CardSection from "../components/common/CardSection";

const { REACT_APP_GATSBY_CLOUD_BUILD_WEBHOOK } = process.env;

const Web = () => {
  const navigate = useNavigate();

  const publishChanges = async () => {
    if (REACT_APP_GATSBY_CLOUD_BUILD_WEBHOOK) {
      await axios.post(REACT_APP_GATSBY_CLOUD_BUILD_WEBHOOK);
    }
  };

  return (
    <>
      <Paper sx={{ pt: 12 }} elevation={0}>
        <Container>
          <Grid container>
            <Grid item>
              <StyledButton
                onClick={publishChanges}
                variant="contained"
                startIcon={<Upload />}
              >
                Publicar cambios
              </StyledButton>
            </Grid>
          </Grid>
          <Grid
            justifyContent="space-between"
            sx={{ mt: 2 }}
            spacing={2}
            container
          >
            <Grid xs={12} md={3} item>
              <StyledButton
                color="primary"
                variant="outlined"
                startIcon={<Category />}
                onClick={() => navigate("/dashboard/categories")}
                fullWidth
              >
                Administrar categorías
              </StyledButton>
            </Grid>
            <Grid xs={12} md={3} item>
              <StyledButton
                color="primary"
                variant="outlined"
                startIcon={<Category />}
                onClick={() => navigate("/dashboard/offers")}
                fullWidth
              >
                Administrar ofertas
              </StyledButton>
            </Grid>
            <Grid xs={12} md={3} item>
              <StyledButton
                color="primary"
                variant="outlined"
                startIcon={<StarRounded />}
                onClick={() => navigate("/dashboard/features")}
                fullWidth
              >
                Administrar características
              </StyledButton>
            </Grid>
          </Grid>
          <Grid sx={{ mt: 2 }} spacing={2} container>
            <Grid xs={12} md={4} item>
              <CardSection
                icon={<Apartment sx={{ fontSize: 30 }} />}
                redirectTo="/dashboard/properties"
                title="Propiedades"
                actionButtonText="Administrar propiedades"
              />
            </Grid>
            <Grid xs={12} md={4} item>
              <CardSection
                icon={<Map sx={{ fontSize: 30 }} />}
                redirectTo="/dashboard/locations"
                title="Ubicaciones"
                actionButtonText="Administrar ubicaciones"
              />
            </Grid>
            <Grid xs={12} md={4} item>
              <CardSection
                icon={<Chat sx={{ fontSize: 30 }} />}
                redirectTo="/dashboard/testimonials"
                title="Testimonios"
                actionButtonText="Administrar testimonios"
              />
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </>
  );
};

export default Web;
