import DecoupledEditor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Editor } from "@tinymce/tinymce-react";
import React, { useRef } from "react";
import axios from "axios";
import { Print, Save } from "@mui/icons-material";
import { Box, Fab } from "@mui/material";
import FloatingButton from "./FloatingButton";
const { REACT_APP_API_HOST } = process.env;

const style = {
  margin: 0,
  top: "auto",
  right: 20,
  bottom: 20,
  left: "auto",
  position: "fixed",
};

export const useCkeditorWrapper = () => {
  const [editorData, setEditorData] = React.useState<any>();
  const [processedData, setProcessedData] = React.useState<any>();
  const editorRef = useRef<any>(null);

  const convertToBase64 = (file) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      return reader.result;
    };
  };

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("file", file);
            axios
              .post(`${REACT_APP_API_HOST}files`, body)
              .then((res) => {
                resolve({ default: res?.data.file?.url });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }
  /*
  function uploadAdapter(loader) {
    return {
      upload: async () => {
        const file = await loader.file;
        const base64 = await convertToBase64(file);
        return {
          default: base64,
        };
      },
    };
  }*/

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return {
    uploadPlugin,
    editorData,
    setEditorData,
    editorRef,
    processedData,
    setProcessedData,
  };
};

export const EditorWrapper = ({
  editorDefaultData,
  onPrintDoc,
  onSaveDoc,
  editorRef,
  processedData,
}) => {
  return (
    <>
      <Box sx={{ "& > :not(style)": { m: 1 } }}>
        {onSaveDoc && (
          <Fab sx={style} onClick={onSaveDoc} color="primary" aria-label="add">
            <Save />
          </Fab>
        )}
      </Box>
      {onPrintDoc && (
        <FloatingButton
          mainAction={onPrintDoc}
          Icon={Print}
          right={100}
          color="secondary"
          ariaLabel="print"
        />
      )}
      <div className="document-editor__toolbar"></div>
      <div className="document-editor__editable-container">
        <Editor
          apiKey="9wk5zvcryp55p32bv7zmrnnrjmkgxlkcmjpw4mnhcfu7xk1z"
          onInit={(_evt, editor) => (editorRef.current = editor)}
          init={{
            plugins:
              "    emoticons  link lists media searchreplace table visualblocks wordcount linkchecker",
            toolbar:
              "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog  typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
            tinycomments_mode: "embedded",
            tinycomments_author: "Author name",
            mergetags_list: [
              { value: "First.Name", title: "First Name" },
              { value: "Email", title: "Email" },
            ],
          }}
          initialValue={editorDefaultData}
        />
      </div>
      {processedData && (
        <div
          dangerouslySetInnerHTML={{
            __html: editorRef.current.getContent(),
          }}
        ></div>
      )}
    </>
  );
};
