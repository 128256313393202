import { Button, Container, Grid, Paper, Switch } from "@mui/material";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { fetchRecords } from "../../actions/fetchRecords";
import { Add, Apartment, ChevronLeft, Delete, Edit } from "@mui/icons-material";
import {
  DeleteModal,
  useModalDelete,
} from "../../components/common/DeleteModal";
import StyledButton from "../../styled/StyledButton";
import { BreadcrumbsBar } from "../../components/common/BreadcrumbsBar";
import { updateRecord } from "../../actions/updateRecord";

type ListContainerType = {
  iterableElements?: [any];
  addButtonText: string;
  resource: string;
  title: string;
  addItemPath: string;
  basePath: string;
  resourcePath: string;
  columns?: GridColDef[];
};

const ListContainer = ({
  iterableElements,
  resource,
  title,
  addItemPath,
  addButtonText,
  columns,
  basePath,
  resourcePath,
}: ListContainerType) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [results, setResults] = useState<any[]>([]);

  const {
    handleModal,
    openModal,
    handleDelete,
    modalTitle,
    setModalTitle,
    setUid,
  } = useModalDelete(resource);

  useEffect(() => {
    getRecords().then();
  }, []);

  async function getRecords() {
    if (iterableElements) {
      return;
    }
    const items = await fetchRecords(resource);

    if (items[resource]) {
      setResults(items[resource]);
      setLoading(false);
    }
  }

  return (
    <>
      <DeleteModal
        handleDelete={handleDelete}
        handleModal={handleModal}
        openModal={openModal}
        modalTitle={modalTitle}
        getRecords={getRecords}
      />
      <Paper sx={{ pt: 12 }} elevation={0}>
        <Container>
          <Grid
            alignContent="center"
            justifyContent="space-between"
            sx={{ mb: 4 }}
            container
          >
            <Grid
              xs={12}
              md={8}
              sx={{
                alignItems: "center",
                display: "flex",
                pb: {
                  xs: 4,
                  md: 0,
                },
              }}
              item
            >
              <BreadcrumbsBar currentPath={[{ label: `${title}` }]} />
            </Grid>
            <Grid xs={12} md={4} item>
              <StyledButton
                fullWidth
                variant="contained"
                startIcon={<Add />}
                onClick={() => navigate(addItemPath)}
              >
                {addButtonText}
              </StyledButton>
            </Grid>
          </Grid>
          <Box sx={{ width: "100%", height: "100%" }}>
            <DataGrid
              loading={loading}
              autoHeight
              rows={iterableElements ? iterableElements : results}
              columns={
                columns
                  ? columns
                  : [
                      {
                        field: "name",
                        headerName: "Nombre",
                        flex: 1,
                        editable: true,
                      },
                      {
                        field: "isVisible",
                        width: 150,
                        headerName: "Visible",
                        renderCell: (cellValues: any) => {
                          const currentRecords = [...results];
                          const index = currentRecords.findIndex(
                            (item) => item.id === cellValues.id
                          );

                          const handleChange = async () => {
                            await updateRecord(
                              resource,
                              {
                                isVisible:
                                  cellValues.value === "undefined"
                                    ? true
                                    : !cellValues.value,
                              },
                              cellValues.id
                            );

                            currentRecords[index].isVisible = !cellValues.value;

                            setResults(currentRecords);
                          };

                          return (
                            <Box
                              sx={{
                                width: "100%",
                                justifyContent: "space-around",
                                display: "flex",
                              }}
                            >
                              <Switch
                                checked={results[index]?.isVisible}
                                disabled={cellValues.value === "undefined"}
                                onChange={handleChange}
                              />
                            </Box>
                          );
                        },
                      },
                      {
                        field: "uid",
                        headerName: "Acciones",
                        width: 320,
                        renderCell: (cellValues) => {
                          return (
                            <Box
                              sx={{
                                width: "100%",
                                justifyContent: "space-around",
                                display: "flex",
                              }}
                            >
                              <StyledButton
                                size="small"
                                color="primary"
                                startIcon={<Edit />}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  navigate(
                                    `/${basePath}/${resourcePath}/editar/${cellValues.id}`
                                  );
                                }}
                              >
                                Editar
                              </StyledButton>
                              <StyledButton
                                size="small"
                                color="error"
                                startIcon={<Delete />}
                                onClick={(event) => {
                                  event.stopPropagation();

                                  setModalTitle(cellValues.row.name); //Set modal title

                                  setUid(cellValues.id.toString()); //Set-up the uid to delete

                                  handleModal(); //Open modal
                                }}
                              >
                                Eliminar
                              </StyledButton>
                            </Box>
                          );
                        },
                      },
                    ]
              }
              pageSize={10}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
            />
          </Box>
          <Grid mt={2} justifyContent="center" container>
            <Grid item>
              <Button
                sx={{ textTransform: "none" }}
                startIcon={<ChevronLeft />}
                onClick={() => navigate("/dashboard")}
              >
                Volver
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </>
  );
};

export default ListContainer;
