import React, { useEffect, useState } from "react";
import { useDropzone, ErrorCode } from "react-dropzone";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { CircularProgress, Grid, IconButton } from "@mui/material";
import axios from "axios";
import CheckIcon from "@mui/icons-material/Check";
import { useTheme } from "@mui/material/styles";
import { Clear, Image, PictureAsPdf } from "@mui/icons-material";
import { red } from "@mui/material/colors";
import { ModalCropper } from "./ModalCropper";
import { resizeImage } from "../../actions/resizeImage";
import { deleteRecord } from "../../actions/deleteRecord";
import PreviewItem from "../../types/PreviewItem";
import previewItem from "../../types/PreviewItem";
import { DeleteModal, useModalDelete } from "./DeleteModal";
type FormatType = ".jpeg" | ".jpg" | ".png" | ".pdf";
const { REACT_APP_API_HOST } = process.env;

const dataURItoBlob = (dataURI: string) => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  //Old Code
  //write the ArrayBuffer to a blob, and you're done
  //var bb = new BlobBuilder();
  //bb.append(ab);
  //return bb.getBlob(mimeString);

  //New Code
  return new Blob([ab], { type: mimeString });
};

export const useDropzoneComponent = (
  maxFiles: number = 1,
  maxSize?: number,
  allowedFormats?: FormatType[]
) => {
  const [files, setFiles] = useState<any>([]);
  const [progress, setProgress] = useState<any>([]);
  const [totalPercentage, setTotalPercentage] = useState<number | undefined>(0);
  const [cover, setCover] = useState<number>(0);
  const [croppedData, setCroppedData] = useState<any>(null);
  const [deletedFiles, setDeletedFiles] = useState<any>([]);
  const [previewItemsArray, setPreviewItemsArray] = useState<previewItem[]>([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: allowedFormats ? allowedFormats : "image/*",
    maxSize,
    maxFiles,
    onDrop: (acceptedFiles, fileRejections) => {
      const arrayFiles = files;

      if (fileRejections && fileRejections.length) {
        const { file, errors } = fileRejections[0];
        alert(
          errors[0].code === ErrorCode.FileTooLarge
            ? maxSize && `Error el archivo pesa más de ${maxSize / 1000000} MB`
            : errors[0].message
        );
      }

      if (maxFiles > 1) {
        acceptedFiles.map((file: any) =>
          arrayFiles.push(
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );

        setFiles([...arrayFiles]);
      } else {
        setFiles([
          Object.assign(acceptedFiles[acceptedFiles.length - 1], {
            preview: URL.createObjectURL(
              acceptedFiles[acceptedFiles.length - 1]
            ),
          }),
        ]);
      }
    },
  });

  const deleteOldFiles = async () => {
    if (deletedFiles) {
      deletedFiles.map(async (file: any) => {
        await deleteRecord("files", file._id);
      });
      return;
    }
  };

  const cleanUploadedFiles = () => {
    const currentFiles = files;
    currentFiles.map((file: any, index: number) => {
      if (file?._id) {
      }
    });

    return currentFiles;
  };

  const processFileBeforeUpload = (
    filesToUpload: any,
    uploadFolder: string,
    propertyId?: string
  ) => {
    let requests: any = [];
    let progressCurrent: number = 0;
    let individualProgress: any = [];

    filesToUpload.map((file: any, index: number) => {
      if (file) {
        //If notUlpload property is false consider as recently selected file and needs to upload to server (put into queue requests)
        const formData = new FormData();

        formData.append("file", file);
        formData.append("folderName", uploadFolder);

        if (propertyId) {
          formData.append("property", propertyId);
        }

        requests.push({
          endpoint: `${REACT_APP_API_HOST}files`,
          data: formData,
          config: {
            onUploadProgress: (progressEvent: any) => {
              if (progressEvent.loaded === progressEvent.total) {
                progressCurrent++;
              }

              individualProgress[index] = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );

              setProgress([...individualProgress]);

              // sum up all file progress percentages to calculate the overall progress
              let totalPercent: any = individualProgress
                ? Object.values(individualProgress).reduce(
                    // @ts-ignore
                    (sum: number, num: number) => sum + num,
                    0
                  )
                : 0;

              setTotalPercentage(
                Math.round((totalPercent / (filesToUpload.length * 100)) * 100)
              );
            },
          },
        });
      }
    });

    return requests;
  };
  const uploadToServer = async (requests: any) => {
    let filesArray: any = [];

    try {
      const result = await axios.all(
        requests.map((req: any) =>
          axios.post(req.endpoint, req.data, req.config)
        )
      );
      result.map((response: any) => {
        if (response.status === 200) {
          const { uid } = response.data.file;
          filesArray.push(uid);
        }
      });
    } catch (e) {
      console.log("Error while uploading files", e);
    }

    return filesArray;
  };
  const startUploading = async (folder: string, propertyId?: string) => {
    let requests: any = [];
    let filesArray: any = [];
    let fileToUpload: any = null;
    let oldFiles: any = [];
    const fileResizePromises = files.map(async (file: any) => {
      if (file.type === "application/pdf") {
        return file;
      }

      if (croppedData) {
        let dataBlob: Blob;
        let metadata = {
          type: file.type,
        };

        dataBlob = dataURItoBlob(croppedData);

        fileToUpload = new File([dataBlob], file.name, metadata);
      } else {
        fileToUpload = file;
      }

      return await resizeImage(fileToUpload); //Starts resizing image;
    });

    const compressedFiles = await Promise.all(fileResizePromises);

    requests = processFileBeforeUpload(compressedFiles, folder, propertyId);

    //If there are deleted files in array
    if (deletedFiles.length > 0) {
      deletedFiles.map(async (file: string) => {
        await deleteRecord("files", file);
      });
    }

    filesArray = await uploadToServer(requests);

    if (previewItemsArray) {
      previewItemsArray.map((file: previewItem) => oldFiles.push(file._id));
    }

    if (oldFiles.length > 0) {
      filesArray = oldFiles.concat(filesArray);
    }

    return filesArray; //returns filesArray with files uids
  };

  return {
    progress,
    setProgress,
    files,
    setFiles,
    getRootProps,
    getInputProps,
    startUploading,
    cover,
    setCover,
    croppedData,
    setCroppedData,
    totalPercentage,
    setTotalPercentage,
    deletedFiles,
    setDeletedFiles,
    deleteOldFiles,
    previewItemsArray,
    setPreviewItemsArray,
  };
};

const thumbInner = {
  position: "relative",
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
  cursor: "pointer",
};

type DropzoneComponentType = {
  progress: any;
  files: any;
  getRootProps: any;
  getInputProps: any;
  setCover: any;
  setFiles: any;
  title?: string;
  withCropper?: boolean;
  croppedData?: any;
  setCroppedData?: any;
  loading?: boolean;
  deletedFiles?: any;
  setDeletedFiles?: any;
  defaultPreview?: any;
  previewItemsArray?: PreviewItem[] | [];
  setPreviewItemsArray?: any;
  icon?: any;
  allowedFormats?: FormatType[];
  isPdf?: boolean;
  fileName?: string;
  aspectRatio?: number;
  fileUid?: string;
  maxWidthImg?: number;
};
export const DropzoneComponent = ({
  fileName,
  isPdf,
  progress,
  files,
  getRootProps,
  getInputProps,
  setCover,
  setFiles,
  title,
  withCropper,
  croppedData,
  setCroppedData,
  loading,
  defaultPreview,
  setDeletedFiles,
  previewItemsArray,
  setPreviewItemsArray,
  icon,
  aspectRatio,
  fileUid,
  maxWidthImg,
}: DropzoneComponentType) => {
  const theme = useTheme();
  const [modalCropper, setModalCropper] = useState(false);
  const [cropper, setCropper] = useState<any>();
  const [fileToCrop, setFileToCrop] = useState<any>();
  const { ...modalDeleteProps } = useModalDelete("files");

  const img = {
    display: "block",
    width: maxWidthImg ? maxWidthImg : "100%",
    borderRadius: 8,
  };

  const handleDelete = (index: number, isPreview: boolean) => {
    if (defaultPreview) {
      setDeletedFiles((prev: any) => [...prev, defaultPreview]);
      setCroppedData(null);
    }

    if (isPreview && previewItemsArray) {
      setDeletedFiles((prev: any) => [...prev, previewItemsArray[index]._id]);
      previewItemsArray.splice(index, 1);
      setPreviewItemsArray([...previewItemsArray]);
    } else {
      setDeletedFiles((prev: any) => [...prev, files[index]._id]);
      files.splice(index, 1); //Deletes selected item
      setFiles([...files]); //Up
    }
  };

  const handleDeleteFilePermanent = async () => {
    if (!fileUid && fileName) return;
    modalDeleteProps.setUid(fileUid ? fileUid : null);
    modalDeleteProps.setModalTitle(fileName ? fileName : "");
    modalDeleteProps.handleModal();
  };

  const thumb: any = {
    position: "relative",
    display: "inline-flex",
    borderRadius: 2,
    border: `1px solid ${theme.palette.primary.main}`,
    width: 100,
    height: 100,
    boxSizing: "border-box",
  };

  const styleTraslucidLayer = {
    position: "absolute",
    zIndex: 2,
    width: "100%",
    height: "100%",
    borderRadius: 2,
    backgroundColor: loading
      ? theme.palette.mode === "dark"
        ? "rgba(0,0,0,0.6)"
        : "rgba( 255, 255, 255, 0.9 )"
      : "transparent",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const thumbs = (arrayItems: any, isPreview: boolean) =>
    arrayItems.map((file: any, index: number) => (
      <Grid key={index} xs={12} md={6} lg={2} item>
        <Box sx={thumb} key={file.name}>
          {!loading && (
            <IconButton
              size="small"
              sx={{
                backgroundColor: red[700],
                color: "white",
                position: "absolute",
                top: -10,
                right: -10,
                zIndex: 10,
              }}
              onClick={() => handleDelete(index, isPreview)}
            >
              <Clear />
            </IconButton>
          )}

          {loading && (
            <Box sx={styleTraslucidLayer}>
              {progress.length > 0 && progress[index] === 100 ? (
                <CheckIcon color="primary" />
              ) : (
                <CircularProgress
                  variant={
                    progress.length > 0 ? "determinate" : "indeterminate"
                  }
                  value={progress.length > 0 ? progress[index] : undefined}
                />
              )}
            </Box>
          )}
          <Box sx={thumbInner} onClick={() => setCover(index)}>
            <img src={file.preview ? file.preview : file.url} style={img} />
          </Box>
        </Box>
      </Grid>
    ));

  useEffect(() => {
    if (files.length > 0) {
      if (withCropper) {
        setFileToCrop(files[files.length - 1].preview);
        setModalCropper(true);
      }
    }
  }, [files]);

  const handleModal = () => {
    setModalCropper(!modalCropper);
  };

  const getCropData = () => {
    if (typeof cropper !== "undefined" && withCropper) {
      setCroppedData(cropper.getCroppedCanvas().toDataURL());
      handleModal();
    }
  };

  const deleteButton = () => (
    <IconButton
      sx={{
        position: "absolute",
        right: -10,
        top: -10,
        backgroundColor: red[400],
        color: "white",
        zIndex: 200,
      }}
      size="small"
      onClick={() =>
        fileUid ? handleDeleteFilePermanent() : setCroppedData(null)
      }
    >
      <Clear />
    </IconButton>
  );
  return (
    <>
      <DeleteModal
        getRecords={() => window.location.reload()}
        {...modalDeleteProps}
      />
      <ModalCropper
        openModal={modalCropper}
        handleModal={handleModal}
        file={fileToCrop}
        setCropper={setCropper}
        getCropData={getCropData}
        aspectRatio={aspectRatio ? aspectRatio : 1.7948717949}
      />
      <Box
        sx={{
          position: "relative",
          height: withCropper ? "100%" : undefined,
        }}
      >
        {fileName && !loading && deleteButton()}
        <Box
          sx={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            pl: 2,
            pr: 2,
            alignContent: "center",
            justifyContent: "center",
            borderWidth: 2,
            borderRadius: 2,
            height: withCropper ? "100%" : 100,
            borderColor: loading
              ? "rgba( 0,0,0, 0.2 )"
              : theme.palette.primary.main,
            borderStyle: fileName ? "solid" : "dashed",
            cursor: "pointer",
          }}
          {...getRootProps({ className: "dropzone" })}
        >
          <input {...getInputProps()} />

          {croppedData ? (
            <>
              {loading && (
                <Box sx={styleTraslucidLayer}>
                  {progress.length > 0 && progress[0] === 100 ? (
                    <CheckIcon color="primary" />
                  ) : (
                    <CircularProgress
                      variant={
                        progress.length > 0 ? "determinate" : "indeterminate"
                      }
                      value={progress.length > 0 ? progress[0] : undefined}
                    />
                  )}
                </Box>
              )}
              {deleteButton()}
              <img alt="preview" src={croppedData} style={img} />
            </>
          ) : (
            <>
              {isPdf && fileName ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <PictureAsPdf
                    sx={{
                      color: "#f40f02",
                    }}
                    fontSize="large"
                  />
                  <Typography align="center" variant="caption">
                    {fileName}
                  </Typography>
                </Box>
              ) : (
                <>
                  {icon ? icon : <Image color="primary" fontSize="large" />}

                  <Typography variant="body2" color="primary">
                    {title
                      ? title
                      : "Arrastra y suelta algunas imágenes o haz click para seleccionarlas..."}
                  </Typography>
                </>
              )}
            </>
          )}
        </Box>
      </Box>
      <Grid container sx={{ mt: 2 }} spacing={3}>
        {previewItemsArray && !withCropper && thumbs(previewItemsArray, true)}
        {files.length > 0 && !withCropper && !fileName && thumbs(files, false)}
      </Grid>
    </>
  );
};
