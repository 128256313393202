import { useEffect, useState } from "react";
import {
  CircularProgress,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { postRecord } from "../../actions/postRecord";
import ServiceType from "../../types/ServiceType";
import { fetchRecord } from "../../actions/fetchRecord";
import { updateRecord } from "../../actions/updateRecord";
import { BreadcrumbsBar } from "../../components/common/BreadcrumbsBar";
import StyledButton from "../../styled/StyledButton";
import { Check } from "@mui/icons-material";
import {
  DropzoneComponent,
  useDropzoneComponent,
} from "../../components/common/DropzoneComponent";
import WithTheme from "../../components/common/WithTheme";

const CategoriesFormPage = () => {
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();
  const params = useParams();
  const dropzoneComponentProps = useDropzoneComponent(20);

  const [result, setResult] = useState<ServiceType | null>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getRecord().then();
  }, []);

  const getRecord = async () => {
    if (params.uid) {
      const { service } = await fetchRecord("services", params.uid);
      setValue("name", service.name);
      setValue("description", service.description);
      setResult(service);
    }
    setLoading(false);
  };

  const navigate = useNavigate();

  const submitForm = async (data: any) => {
    setLoading(true);
    let result: any;

    const imagesArray = await dropzoneComponentProps.startUploading(
      `servicios/${data.name}/gallery`
    );

    //if imagesArray > 0 assing to main data
    if (imagesArray.length > 0) {
      data.images = imagesArray;
    }

    if (params.uid) {
      result = await updateRecord("services", data, params.uid); //If updating
    } else {
      result = await postRecord("services", data); //If creating
    }

    setLoading(false);
    if (result) {
      navigate("/web/servicios");
    }
  };

  return (
    <Paper sx={{ pt: 12 }} elevation={0}>
      <Container maxWidth="md">
        <BreadcrumbsBar
          currentPath={[
            { to: "/web/servicios", label: "Servicios" },
            { label: params.uid ? "Editar servicio" : "Crear servicio" },
          ]}
        />
        <form id="services-form" onSubmit={handleSubmit(submitForm)}>
          <Grid spacing={2} container>
            <Grid xs={12} item>
              <Controller
                name="name"
                defaultValue={result ? result.description : undefined}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    label="nombre"
                  />
                )}
              />
            </Grid>
            <Grid xs={12} item>
              <Controller
                name="description"
                defaultValue={result ? result.description : undefined}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    onChange={onChange}
                    value={value}
                    multiline
                    label="Descripción"
                  />
                )}
              />
            </Grid>
            <Grid xs={12} item>
              <DropzoneComponent {...dropzoneComponentProps} />
            </Grid>
            <Grid
              spacing={2}
              sx={{ my: 2, justifyContent: "center" }}
              container
            >
              <Grid
                sx={{
                  order: {
                    xs: 2,
                    md: 1,
                  },
                }}
                xs={12}
                sm={4}
                item
              >
                <StyledButton
                  fullWidth
                  variant="outlined"
                  onClick={() => navigate("/dashboard/categories")}
                >
                  Cancelar
                </StyledButton>
              </Grid>
              <Grid
                sx={{
                  order: {
                    xs: 1,
                    md: 2,
                  },
                }}
                xs={12}
                sm={4}
                item
              >
                <StyledButton
                  fullWidth
                  variant="contained"
                  disabled={loading}
                  startIcon={
                    loading ? (
                      <CircularProgress color="inherit" size={12} />
                    ) : (
                      <Check />
                    )
                  }
                  onClick={handleSubmit(submitForm)}
                  type="button"
                >
                  {loading ? "Creando servicio..." : "Crear servicio"}
                </StyledButton>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Paper>
  );
};

export default WithTheme(CategoriesFormPage);
