import { Route } from "react-router-dom";
import * as React from "react";
import ServicesList from "../pages/services/ServicesList";
import ServicesForm from "../pages/services/ServicesForm";
import Receipt from "../pages/accounting/receipts/Receipt";

export const servicesBaseUrl = "servicios";

export const useServicesRoutes = () => (
  <React.Fragment>
    <Route path="/web/servicios" element={<ServicesList />} />
    <Route path="/web/servicios/:uid" element={<Receipt />} />
    <Route path="/web/servicios/nuevo" element={<ServicesForm />} />
    <Route path="/web/servicios/editar/:uid" element={<ServicesForm />} />
  </React.Fragment>
);
