import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  CircularProgress,
  Container,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { Check } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { postRecord } from "../../../actions/postRecord";
import { fetchRecord } from "../../../actions/fetchRecord";
import { updateRecord } from "../../../actions/updateRecord";
import StyledButton from "../../../styled/StyledButton";
import Box from "@mui/material/Box";
import ClientsType from "../../../types/ClientsType";
import MaritalStatusSelect from "../../../components/common/MaritalStatusSelect";
import StatesSelect from "../../../components/common/StatesSelect";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs, { Dayjs } from "dayjs";
import { DateField } from "@mui/x-date-pickers/DateField/DateField";
import { DatePicker } from "@mui/x-date-pickers";

const __TITLE__ = "vendedor";
const __RESOURCE__ = "sellers";

const SellersForm = ({
  customDismiss,
  getRecords,
  recordId,
  embedded = false,
}: {
  customDismiss?: any;
  getRecords?: any;
  recordId?: string | null;
  embedded?: boolean;
}) => {
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();
  const [isFormReady, setIsFormReady] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [valueFrom, setValueDateFrom] = React.useState<Dayjs | null>(
    dayjs("12/12/1990")
  );
  const params = useParams();
  const uid = recordId || params?.uid;

  const [result, setResult] = useState<ClientsType | null>(null);

  useEffect(() => {
    getRecord().then();
  }, [uid]);

  const getRecord = async () => {
    setIsFormReady(false);
    if (uid) {
      const { seller } = await fetchRecord(__RESOURCE__, uid);

      setValue("name", seller.name);
      setValue("parentalLastName", seller.parentalLastName);
      setValue("maternalLastName", seller.maternalLastName);
      setValue("ineNumber", seller.ineNumber);
      setValue("homeAddress", seller.homeAddress);
      setValue("email", seller.email);
      setValue("homePhoneNumber", seller?.homePhoneNumber);
      setValue("cellphoneNumber", seller?.cellphoneNumber);
      setValue("workPhoneNumber", seller?.workPhoneNumber);
      setValue("genre", seller?.genre);
      setValue("homeAddress", seller?.homeAddress);
      setValue("workAddress", seller?.workAddress);
      setValue("city", seller?.city);
      setValue("state", seller?.state);
      setValue("postalCode", seller?.postalCode);

      setResult(seller);
    }

    setIsFormReady(true);
  };

  const handleChangeFrom = (newValue: Dayjs | null) => {
    setValueDateFrom(newValue);
  };

  const navigate = useNavigate();

  const submitForm = async (data: any) => {
    let record: any;
    data.birthDate = valueFrom?.unix();
    setLoading(true);

    if (uid) {
      record = await updateRecord(__RESOURCE__, data, uid); //If updating
    } else {
      record = await postRecord(__RESOURCE__, data); //If creating
    }

    setLoading(false);

    if (record && !embedded) {
      navigate(`/contabilidad/vendedores`);
    }
  };

  return (
    <Paper sx={{ pt: embedded ? 0 : 12, pb: 2 }} elevation={embedded ? 1 : 0}>
      <Container maxWidth="md">
        {!embedded && (
          <Typography align="center" variant="h6">
            {params.uid ? `Editar ${__TITLE__}` : `Crear ${__TITLE__}`}
          </Typography>
        )}
        {isFormReady ? (
          <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <form onSubmit={handleSubmit(submitForm)}>
                <Grid spacing={2} container>
                  <Grid xs={12} item>
                    <Controller
                      name="name"
                      defaultValue={result ? result.name : undefined}
                      control={control}
                      rules={{
                        required: "El nombre es requerido.",
                      }}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          onChange={onChange}
                          value={value}
                          label="Nombre(s)"
                        />
                      )}
                    />
                    {errors.name && (
                      <Typography variant="caption" sx={{ color: "red" }}>
                        {errors.name.message}
                      </Typography>
                    )}
                  </Grid>
                  <Grid xs={12} md={6} item>
                    <Controller
                      name="parentalLastName"
                      defaultValue={
                        result ? result.parentalLastName : undefined
                      }
                      control={control}
                      rules={{
                        required: "El apellido paterno es requerido.",
                      }}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          onChange={onChange}
                          value={value}
                          label="Apellido paterno"
                        />
                      )}
                    />
                    {errors.parentalLastName && (
                      <Typography variant="caption" sx={{ color: "red" }}>
                        {errors.parentalLastName.message}
                      </Typography>
                    )}
                  </Grid>
                  <Grid xs={12} md={6} item>
                    <Controller
                      name="maternalLastName"
                      defaultValue={
                        result ? result.maternalLastName : undefined
                      }
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          onChange={onChange}
                          value={value}
                          label="Apellido materno"
                        />
                      )}
                    />
                  </Grid>
                  <Grid xs={12} item>
                    <Controller
                      name="email"
                      defaultValue={result ? result.email : undefined}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          onChange={onChange}
                          value={value}
                          label="E-mail"
                        />
                      )}
                    />
                  </Grid>
                  <Grid xs={12} md={4} item>
                    <Controller
                      name="homePhoneNumber"
                      defaultValue={result ? result.homePhoneNumber : undefined}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          onChange={onChange}
                          value={value}
                          label="Teléfono de casa"
                        />
                      )}
                    />
                  </Grid>
                  <Grid xs={12} md={4} item>
                    <Controller
                      name="cellphoneNumber"
                      defaultValue={result ? result.cellphoneNumber : undefined}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          onChange={onChange}
                          value={value}
                          label="Teléfono celular"
                        />
                      )}
                    />
                  </Grid>
                  <Grid xs={12} md={4} item>
                    <Controller
                      name="workPhoneNumber"
                      defaultValue={result ? result.workPhoneNumber : undefined}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          onChange={onChange}
                          value={value}
                          label="Teléfono de trabajo"
                        />
                      )}
                    />
                  </Grid>
                  <Grid xs={12} md={6} item>
                    <Controller
                      name="maritalStatus"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <MaritalStatusSelect
                          disabled={loading}
                          defaultValue={
                            result ? result?.maritalStatus : undefined
                          }
                          onChange={onChange}
                        />
                      )}
                    />
                    {errors.maritalStatus && (
                      <Typography variant="caption" sx={{ color: "red" }}>
                        {errors.maritalStatus.message}
                      </Typography>
                    )}
                  </Grid>
                  <Grid xs={12} md={6} item>
                    <Controller
                      name="ineNumber"
                      defaultValue={result ? result.ineNumber : undefined}
                      control={control}
                      rules={{
                        required: "El folio del INE es requerido.",
                      }}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          onChange={onChange}
                          value={value}
                          label="Folio INE"
                        />
                      )}
                    />
                    {errors.ineNumber && (
                      <Typography variant="caption" sx={{ color: "red" }}>
                        {errors.ineNumber.message}
                      </Typography>
                    )}
                  </Grid>
                  <Grid xs={6} item>
                    <Controller
                      name="birthDate"
                      defaultValue={result ? result.occupation : undefined}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <DateField
                          label="Fecha de nacimiento"
                          value={valueFrom}
                          format="DD/MM/YYYY"
                          onChange={handleChangeFrom}
                          // @ts-ignore
                          renderInput={(params: any) => (
                            <TextField {...params} fullWidth />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid xs={6} item>
                    <Controller
                      name="genre"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          disabled={loading}
                          disablePortal
                          loading={loading}
                          loadingText="Cargando..."
                          defaultValue={value}
                          onChange={(event: any, newValue: any) => {
                            if (newValue) {
                              onChange(newValue.value);
                            }
                          }}
                          renderOption={(props: any, option) => {
                            return <li {...props}>{option.label}</li>;
                          }}
                          id="Género"
                          options={[
                            {
                              label: "Masculino",
                              value: "M",
                            },
                            {
                              label: "Femenino",
                              value: "F",
                            },
                          ]}
                          renderInput={(params) => (
                            <TextField {...params} label={"Género"} />
                          )}
                        />
                      )}
                    />
                    {errors.genre && (
                      <Typography variant="caption" sx={{ color: "red" }}>
                        {errors.genre.message}
                      </Typography>
                    )}
                  </Grid>
                  <Grid xs={12} item>
                    <Controller
                      name="homeAddress"
                      defaultValue={result ? result.homeAddress : undefined}
                      control={control}
                      rules={{
                        required: "El domicilio de casa es requerido.",
                      }}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          onChange={onChange}
                          value={value}
                          label="Dirección (Calle, no. exterior, no. interior, colonia)"
                        />
                      )}
                    />
                    {errors.homeAddress && (
                      <Typography variant="caption" sx={{ color: "red" }}>
                        {errors.homeAddress.message}
                      </Typography>
                    )}
                  </Grid>
                  <Grid xs={12} item>
                    <Controller
                      name="workAddress"
                      defaultValue={result ? result.workAddress : undefined}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          onChange={onChange}
                          value={value}
                          label="Dirección de trabajo (Calle, no. exterior, no. interior, colonia)"
                        />
                      )}
                    />
                  </Grid>
                  <Grid xs={12} md={4} item>
                    <Controller
                      name="city"
                      defaultValue={result ? result.city : undefined}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          onChange={onChange}
                          value={value}
                          label="Ciudad"
                        />
                      )}
                    />
                  </Grid>
                  <Grid xs={12} md={4} item>
                    <Controller
                      name="state"
                      defaultValue={result ? result.state : undefined}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <StatesSelect
                          defaultValue={value}
                          disabled={loading}
                          onChange={onChange}
                        />
                      )}
                    />
                  </Grid>
                  <Grid xs={12} md={4} item>
                    <Controller
                      name="postalCode"
                      defaultValue={result ? result.postalCode : undefined}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          onChange={onChange}
                          value={value}
                          label="Código Postal"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid
                  spacing={2}
                  sx={{ my: 2, justifyContent: "center" }}
                  container
                >
                  <Grid
                    sx={{
                      order: {
                        xs: 2,
                        md: 1,
                      },
                    }}
                    xs={12}
                    sm={4}
                    item
                  >
                    <StyledButton
                      fullWidth
                      variant="outlined"
                      onClick={() => navigate(`/contabilidad/vendedores`)}
                    >
                      Cancelar
                    </StyledButton>
                  </Grid>
                  <Grid
                    sx={{
                      order: {
                        xs: 1,
                        md: 2,
                      },
                    }}
                    xs={12}
                    sm={4}
                    item
                  >
                    <StyledButton
                      fullWidth
                      variant="contained"
                      disabled={loading}
                      startIcon={
                        loading ? (
                          <CircularProgress color="inherit" size={12} />
                        ) : (
                          <Check />
                        )
                      }
                      type="submit"
                    >
                      {loading
                        ? uid
                          ? `Actualizando ${__TITLE__}...`
                          : `Creando ${__TITLE__}`
                        : uid
                        ? `Actualizar ${__TITLE__}...`
                        : `Crear ${__TITLE__}`}
                    </StyledButton>
                  </Grid>
                </Grid>
              </form>
            </LocalizationProvider>
          </>
        ) : (
          <Box
            sx={{
              width: "100%",
              height: 400,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Container>
    </Paper>
  );
};

export default SellersForm;
