import ListContainer from "../../components/common/ListContainer";
import { webUrlBase } from "../../constants/site";
import { servicesBaseUrl } from "../../routes/useServicesRoutes";
import { useNavigate } from "react-router-dom";

const ServicesList = () => {
  const navigate = useNavigate();

  return (
    <>
      <ListContainer
        resource="services"
        title="Servicios"
        basePath={webUrlBase}
        resourcePath={servicesBaseUrl}
        addButtonText="Nuevo servicio"
        addItemPath={`/${webUrlBase}/${servicesBaseUrl}/nuevo`}
      />
    </>
  );
};

export default ServicesList;
